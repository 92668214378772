<template>
  <div class="Talent">
    <div class="Talent_content">
      <div class="left">
        <img src="../assets/image/012.png" />
        <div class="lefttu">
          <img src="../assets/image/013.png" />
          <img src="../assets/image/014.png" class="tu01" />
          <img src="../assets/image/logoq.png" class="tu02" />
        </div>
      </div>
      <div class="right">
        <img src="../assets/image/011.png" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.Talent {
  margin-top:30px;
  .Talent_content {
    width: 1300px;
    height: 600px;
    background-color: rgb(255, 255, 255);
    .left {
      width: 850px;
      height: 600px;
      background-color: rgb(255, 255, 255);
      float: left;
      text-align: center;
      margin-top: 30px;
      .lefttu {
        margin-top: 40px;
        .tu01 {
          margin-top: 100px;
        }
        .tu02 {
          margin-top: 40px;
        }
      }
    }
    .right {
      width: 400px;
      height: 600px;
      background-color: rgb(255, 255, 255);
      float: right;
      img {
        margin-left: 40px;
      }
    }
  }
}
</style>
